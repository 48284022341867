'use client';

import {
	BulkAccountLoader,
	HighLeverageModeConfigAccountSubscriber,
	PollingHighLeverageModeConfigAccountSubscriber,
	WebSocketHighLeverageModeConfigAccountSubscriber,
	getHighLeverageModeConfigPublicKey,
} from '@drift-labs/sdk';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import useCurrentSettings from 'src/hooks/useCurrentSettings';

const INITIAL_CONTEXT_VALUE: {
	highLeverageModeConfigSubscriber: HighLeverageModeConfigAccountSubscriber;
} = {
	highLeverageModeConfigSubscriber: undefined,
};

export const HighLeverageModeConfigSubscriberContext = React.createContext<{
	highLeverageModeConfigSubscriber: HighLeverageModeConfigAccountSubscriber;
}>(INITIAL_CONTEXT_VALUE);

const HighLeverageModeConfigSubscriberProvider = (
	props: PropsWithChildren<any>
) => {
	const connection = useDriftStore((s) => s.connection.current);
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const bulkAccountLoader = useDriftStore((s) => s.currentBulkAccountLoader);
	const [userSettings] = useCurrentSettings();
	const usePolling = userSettings.accountSubscriberType !== 'websocket';

	const [contextValue, setContextValue] = useState(INITIAL_CONTEXT_VALUE);
	const subscribersRef = useRef(contextValue);

	const createSubscriber = async (
		program: ConstructorParameters<
			typeof PollingHighLeverageModeConfigAccountSubscriber
		>[0],
		usePolling: boolean,
		bulkAccountLoader?: BulkAccountLoader
	) => {
		const publicKey = await getHighLeverageModeConfigPublicKey(
			driftClient.program.programId
		);
		const sub = usePolling
			? new PollingHighLeverageModeConfigAccountSubscriber(
					program,
					publicKey,
					bulkAccountLoader
			  )
			: new WebSocketHighLeverageModeConfigAccountSubscriber(
					program,
					publicKey,
					3000, //todo - confirm this
					DEFAULT_COMMITMENT_LEVEL
			  );
		await sub.subscribe();
		return sub;
	};

	// set up subscribers
	useEffect(() => {
		if (
			!connection ||
			!driftClient ||
			!driftClient.isSubscribed ||
			!bulkAccountLoader
		)
			return;

		const createAndSubscribe = async () => {
			const highLeverageModeConfigSubscriber = await createSubscriber(
				driftClient.program,
				usePolling,
				bulkAccountLoader
			);

			setContextValue({
				highLeverageModeConfigSubscriber,
			});

			subscribersRef.current = {
				highLeverageModeConfigSubscriber,
			};
		};

		const teardown = () => {
			subscribersRef.current?.highLeverageModeConfigSubscriber?.unsubscribe();
			setContextValue(INITIAL_CONTEXT_VALUE);
		};

		createAndSubscribe();

		return teardown;
	}, [connection, driftClient, bulkAccountLoader, usePolling]);

	return (
		<HighLeverageModeConfigSubscriberContext.Provider value={contextValue}>
			{props.children}
		</HighLeverageModeConfigSubscriberContext.Provider>
	);
};

export default HighLeverageModeConfigSubscriberProvider;
