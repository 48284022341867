'use client';

import { MarketType } from '@drift-labs/sdk';
import { ENUM_UTILS, MarketId } from '@drift/common';
import React, { useEffect, useState } from 'react';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Text from 'src/components/Text/Text';
import use24hMarketDataFromStore from 'src/hooks/use24hMarketDataFromStore';
import Tooltip from '../Tooltip/Tooltip';
import StatDisplay from './StatDisplay';
import { DriftWindow } from '../../window/driftWindow';

export const useRolling24HVolume = (marketId: MarketId) => {
	const [volume, setVolume] = useState(0);
	const [hasLoadedInitialData, setHasLoadedInitialData] = useState(false);
	const marketsData = use24hMarketDataFromStore();

	useEffect(() => {
		const getAndSetVolumeData = () => {
			if (marketsData && marketsData.length !== 0) {
				const selectedMarketData = marketsData.find(
					(mkt) =>
						mkt.marketIndex === marketId.marketIndex &&
						ENUM_UTILS.match(mkt.marketType, marketId.marketType)
				);

				if (selectedMarketData) {
					setVolume(selectedMarketData.quoteVolume);
					setHasLoadedInitialData(true);
					DriftWindow.recordStartupTimeMetric('marketStatsLoaded');
				} else {
					setHasLoadedInitialData(false);
				}
			}
		};

		getAndSetVolumeData();

		const interval = setInterval(getAndSetVolumeData, 1000 * 60);

		return () => {
			clearInterval(interval);
		};
	}, [
		marketId.marketIndex,
		ENUM_UTILS.toStr(marketId.marketType),
		marketsData,
	]);

	return {
		hasLoadedInitialData,
		volume,
	};
};

const _Rolling24HrVolumeNumberDisplay = ({
	marketType,
	marketIndex,
	noLabel,
}: {
	marketType?: MarketType;
	marketIndex?: number;
	noLabel?: boolean;
}) => {
	const marketId = new MarketId(marketIndex, marketType);

	const { hasLoadedInitialData, volume } = useRolling24HVolume(marketId);

	const content = (
		<>
			{' '}
			{hasLoadedInitialData ? (
				<div className="flex space-x-2">
					<div className="flex w-full space-x-1">
						<StatDisplay.NumberDisplay
							value={volume}
							symbolLeft="$"
							millify={true}
						/>
					</div>
				</div>
			) : (
				<SkeletonValuePlaceholder
					className="w-full xs:h-[12px] sm:h-[14px] xs:my-[0.5px] sm:my-[2px]"
					loading={true}
				/>
			)}
		</>
	);

	if (noLabel) {
		return content;
	}

	return (
		<Tooltip
			content={
				<div className="text-xs">
					Total volume traded in the market over the past 24 hours.
				</div>
			}
		>
			<div className="flex flex-col">
				<Text.MICRO1>
					<StatDisplay header="24h Volume" leftBorder>
						<div className="flex flex-col text-text-default-2">{content}</div>
					</StatDisplay>
				</Text.MICRO1>
			</div>
		</Tooltip>
	);
};

export const Rolling24HrVolumeNumberDisplay = React.memo(
	_Rolling24HrVolumeNumberDisplay
);
