'use client';

import { ChevronDown, Info } from '@drift-labs/icons';
import {
	AMM_RESERVE_PRECISION_EXP,
	BASE_PRECISION_EXP,
	BigNum,
	MarketType,
	PERCENTAGE_PRECISION_EXP,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import React, { memo, ReactNode, useCallback, useState } from 'react';
import Text from 'src/components/Text/Text';
import useDriftActions from 'src/hooks/useDriftActions';
import { LiquidityPool } from 'src/hooks/useLiquidityPools';
import useMarketInfoDisplayData from 'src/hooks/useMarketInfoDisplayData';
import { LPOpenOrderData } from 'src/hooks/useOpenOrdersData';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import useInfoForCurrentlySelectedMarket, {
	marketHasInsuranceFund,
} from '../../hooks/useInfoForCurrentlySelectedMarket';
import Button from '../Button';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Tooltip from '../Tooltip/Tooltip';
import UIHelpTextLink from '../UIHelpTextLink';
import MarketIcon from '../Utils/MarketIcon';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import NumberDisplayV2 from '../Utils/NumberDisplayV2';
import PreLaunchMarketIcon from '../PreLaunchMarketAlerts/PreLaunchMarketIcon';
import { getIsPreLaunchMarket } from '../PreLaunchMarketAlerts/PreLaunchMarketAlertUtils';
import { isDev } from 'src/environmentVariables/EnvironmentVariables';
import UI_UTILS from 'src/utils/uiUtils';
import { FundingRateInfoAndTooltip } from './FundingRateInfo';
import { Rolling24HrVolumeNumberDisplay } from './Rolling24hrVolumeNumberDisplay';
import { twMerge } from 'tailwind-merge';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import useCalculateTradeFee from 'src/hooks/useCalculateTradeFee';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import { UIMarket } from '@drift/common';

export const DetailsRow = (props: {
	children: ReactNode;
	fullWidth?: boolean;
}) => (
	<div
		className={`py-[10px] flex items-center flex-row justify-between min-w-[200px] items-start space-x-4 border-b border-container-border last:border-none ${
			props.fullWidth ? 'w-full' : ''
		}`}
	>
		{props.children}
	</div>
);

const SpotMarketDetails = ({
	marketIndex,
	fullWidth,
}: {
	marketIndex: number;
	fullWidth?: boolean;
}) => {
	const isMobile = useIsMobileScreenSize();
	const borrowMarketData = useDriftStore((s) => s.borrowLendData);
	const marketInfo = useMarketsInfoStore(
		(s) => s.allMarketsInfo.spotLookup[marketIndex]
	);
	const marketFees = useCalculateTradeFee({
		quoteSize: 0,
		marketType: MarketType.SPOT,
		marketIndex,
	});
	const marketAccount = marketInfo?.account;
	const marketPrecisionExp = marketInfo.config.precisionExp;

	const imfFactor = marketAccount?.imfFactor;
	const displayImfFactor = imfFactor
		? BigNum.from(imfFactor, PERCENTAGE_PRECISION_EXP).prettyPrint()
		: null;

	const orderTickSize = marketAccount?.orderTickSize;
	const displayOrderTickSize = orderTickSize
		? BigNum.from(orderTickSize, PRICE_PRECISION_EXP).prettyPrint()
		: null;

	const orderStepSize = marketAccount?.orderStepSize;
	const displayOrderStepSize = BigNum.from(
		orderStepSize,
		marketPrecisionExp
	).prettyPrint();

	const minOrderSize = marketAccount?.minOrderSize;
	const displayMinOrderSize = minOrderSize
		? BigNum.from(minOrderSize, marketPrecisionExp).prettyPrint()
		: null;

	const maintLiabilityWeight = marketAccount?.maintenanceLiabilityWeight / 100;
	const mainAssetWeight = marketAccount?.maintenanceAssetWeight / 100;

	const borrowAvailability = borrowMarketData?.find(
		(market) => market.bankConfig.marketIndex === marketIndex
	)?.borrowLimitBase;

	return (
		<div className="text-text-default">
			{isMobile && (
				<DetailsRow fullWidth={fullWidth}>
					<Text.BODY3>24h Volume</Text.BODY3>
					<Text.BODY3>
						<Rolling24HrVolumeNumberDisplay
							noLabel
							marketIndex={marketIndex}
							marketType={MarketType.SPOT}
						/>
					</Text.BODY3>
				</DetailsRow>
			)}
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Taker Fee / Maker Rebate</Text.BODY3>
				<Text.BODY3>{`${parseFloat(
					marketFees.takerFeePct.toFixed(5)
				)}% / ${parseFloat(
					Math.abs(marketFees.makerFeePct).toFixed(5)
				)}%`}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Min. Order Size</Text.BODY3>
				<Text.BODY3>{displayMinOrderSize}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Tooltip content={`Smallest unit of change for the price of an order`}>
					<Text.BODY3>Order Tick Size</Text.BODY3> <Info className="ml-1" />
				</Tooltip>
				<Text.BODY3>{displayOrderTickSize}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Tooltip content={`Smallest unit of change for the size of an order`}>
					<Text.BODY3>Order Step Size</Text.BODY3> <Info className="ml-1" />
				</Tooltip>
				<Text.BODY3>{displayOrderStepSize}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>IMF Factor</Text.BODY3>
				<Text.BODY3>{displayImfFactor}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Asset Weight (Maintenance)</Text.BODY3>
				<Text.BODY3>{mainAssetWeight}%</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Liability Weight (Maintenance)</Text.BODY3>
				<Text.BODY3>{maintLiabilityWeight}%</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Available to Borrow</Text.BODY3>
				<Text.BODY3>
					{borrowAvailability?.toMillified() ?? 0} {marketInfo.config.symbol}
				</Text.BODY3>
			</DetailsRow>
		</div>
	);
};

export const SpotMarketDetailsMemo = memo(SpotMarketDetails);

/**
 * Returns a list of rows with the market details for given perp market index
 */
const PerpMarketDetails = ({
	marketIndex,
	fullWidth,
}: {
	marketIndex: number;
	fullWidth?: boolean;
}) => {
	const [openInterestExpanded, setOpenInterestExpanded] = useState(false);
	const isLiteMode = useIsLiteMode();
	const actions = useDriftActions();
	const isMobile = useIsMobileScreenSize();
	const selectedMarketBaseAssetSymbol = useDriftStore((s) =>
		s.selectedMarket.current.baseAssetSymbol(true)
	);
	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	const marketInfo = useMarketsInfoStore(
		(s) => s.allMarketsInfo.perpLookup[marketIndex]
	);
	const selectedMarketOracle = useDriftStore(
		(s) => s.selectedMarket.current.market.oracle
	);
	const marketAccount = marketInfo?.account;

	const marketData = useMarketInfoDisplayData();
	const selectedMarketInfo = useInfoForCurrentlySelectedMarket();

	const marketFees = useCalculateTradeFee({
		quoteSize: 0,
		marketType: MarketType.PERP,
		marketIndex,
	});

	const imfFactor = marketAccount?.imfFactor;
	const orderTickSize = marketAccount?.amm?.orderTickSize;
	const orderStepSize = marketAccount?.amm?.orderStepSize;

	const extendedInfo = useMarketsInfoStore(
		(s) => s.allMarketsInfo.perpLookup[marketIndex]?.extendedInfo
	);

	const oracleData = useMarketStateStore((s) =>
		s.getOracleDataForMarket(selectedMarketId)
	);

	const isPreLaunchMarket = getIsPreLaunchMarket(
		selectedMarketId,
		marketAccount
	);

	const { minOrderSize, marginMaintenance, availableInsurance, pnlPoolValue } =
		extendedInfo || {};

	const displayMinOrderSize = minOrderSize
		? BigNum.from(minOrderSize, BASE_PRECISION_EXP).prettyPrint()
		: null;
	const displayOrderTickSize = orderTickSize
		? BigNum.from(orderTickSize, PRICE_PRECISION_EXP).prettyPrint()
		: null;
	const displayOrderStepSize = BigNum.from(
		orderStepSize,
		AMM_RESERVE_PRECISION_EXP
	).prettyPrint();

	const displayImfFactor = imfFactor
		? BigNum.from(imfFactor, PERCENTAGE_PRECISION_EXP).prettyPrint()
		: null;
	const displayMarginMaintenance = marginMaintenance / 100;
	const displayAvailableInsurance = availableInsurance
		? BigNum.from(availableInsurance, QUOTE_PRECISION_EXP).toMillified()
		: null;
	const displayPnlPoolValue = pnlPoolValue
		? BigNum.from(pnlPoolValue, QUOTE_PRECISION_EXP).toMillified()
		: null;

	const displayLiquidationFee = `${
		(marketAccount.liquidatorFee + marketAccount.ifLiquidationFee) / 10000
	}%`;

	const hasLimitedInsurance = !marketHasInsuranceFund(marketAccount);

	const fundingIsDue =
		marketData && marketData?.estimatedFundingRateCountdownTs <= 0;

	const toggleOpenInterestExpanded = () => {
		setOpenInterestExpanded(!openInterestExpanded);
	};

	const updateFundingRate = () => actions.updateFundingRate(marketIndex);

	return (
		<div className="text-text-default">
			{/* Oracle price display on mobile and lite mode at the top */}
			{(isMobile || isLiteMode) && (
				<DetailsRow>
					<Tooltip
						allowHover
						content={
							<div className="text-xs">
								The current Oracle Price of the selected asset (
								{selectedMarketBaseAssetSymbol}).
								<br />
								<code>
									{marketData.indexPrice + ' ± ' + marketData.indexErr}
									<br />
								</code>
								{!isPreLaunchMarket && (
									<>
										<br />
										Oracle provided by
										<a
											href={
												UI_UTILS.isPythOracle(marketData.oracleSource)
													? `https://pyth.network/price-feeds/crypto-${selectedMarketBaseAssetSymbol.toLowerCase()}-usd/?cluster=${
															isDev() ? `solana-devnet` : `solana-mainnet-beta`
													  }`
													: 'https://switchboard.xyz/explorer/3/' +
													  selectedMarketOracle
											}
											target="_blank"
											rel="noreferrer"
										>
											{' '}
											{UI_UTILS.isPythOracle(marketData.oracleSource)
												? 'Pyth'
												: 'Switchboard'}
										</a>
										.
										<br />
										{marketData.lastOracleSlotDelay >= 10 ? (
											<>
												{'⚠️ Oracle Status: Delayed/Stale Data (Updated ' +
													`${marketData.lastOracleSlotDelay}` +
													' slots ago)'}
												<br /> <br />
												{'The price feed has not updated in the last 10 slots, so AMM might be unavailable for fills.' +
													'The order matching is still available.'}
												<UIHelpTextLink
													href="https://docs.drift.trade/trading/oracles"
													text="More Info..."
													className="mt-2"
												/>
											</>
										) : parseFloat(marketData.indexErr) /
												marketData.indexPrice >=
										  0.02 ? (
											<>
												{'⚠️ Oracle Status: Confidence Interval Too Large (Updated ' +
													`${
														(parseFloat(marketData.indexErr) /
															marketData.indexPrice) *
														100
													}` +
													'% )'}
												<br /> <br />
												{'The price feed confidence interval too large, so AMM might be unavailable for fills.' +
													'The order matching is still available.'}
												<UIHelpTextLink
													href="https://docs.drift.trade/trading/oracles"
													text="More Info..."
													className="mt-2"
												/>
											</>
										) : (
											''
										)}
									</>
								)}
							</div>
						}
					>
						<Text.BODY3 className="flex flex-row items-center">
							Oracle Price <Info className="ml-1" />
						</Text.BODY3>
					</Tooltip>
					{marketData.hasLoadedInitialData &&
					selectedMarketInfo.info &&
					marketData?.indexPrice ? (
						<div className="flex items-center">
							<div className="w-6 h-3">
								{isPreLaunchMarket ? (
									<PreLaunchMarketIcon
										color={`var(--text-label)`}
										className="ml-1 relative top-[-3px]"
									/>
								) : UI_UTILS.isPythOracle(marketData.oracleSource) ? (
									<img
										src={`/assets/icons/oracles/pyth.svg`}
										className="w-[13px] h-[13px] ml-1"
									/>
								) : (
									<img
										src={`/assets/icons/oracles/switchboard.svg`}
										className="w-[16px] h-[16px] ml-1 relative top-[-3px]"
									/>
								)}
							</div>
							<div>
								<Text.BODY2 className="text-text-default">
									<NumberDisplayV2
										value={BigNum.from(marketData.indexPrice * 10 ** 6, 6)}
										subType="$"
										displayType="notional"
										toFixed={
											selectedMarketInfo.info.genericInfo.tickSizeExponent
										}
									/>
								</Text.BODY2>
							</div>
						</div>
					) : (
						<SkeletonValuePlaceholder
							className="w-full xs:h-[12px] sm:h-[14px] xs:my-[0.5px] sm:my-[2px]"
							loading={true}
						/>
					)}
				</DetailsRow>
			)}
			{isPreLaunchMarket && (
				<DetailsRow fullWidth={fullWidth}>
					<Tooltip
						content={`Max Price only applies to pre-launch markets and may be adjusted.`}
					>
						<Text.BODY3 className="flex flex-row items-center">
							Max Price <Info className="ml-1" />
						</Text.BODY3>
					</Tooltip>
					<Text.BODY3>{oracleData?.maxPrice?.toNotional()}</Text.BODY3>
				</DetailsRow>
			)}
			{isMobile && (
				<FundingRateInfoAndTooltip
					marketSymbol={marketData.symbol}
					selectedMarketId={selectedMarketId}
					detailsModalDisplay={true}
				/>
			)}
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Next Funding Update</Text.BODY3>
				<Text.BODY3>
					{fundingIsDue && (
						<Button.Secondary
							className="mr-1"
							onClick={updateFundingRate}
							size="SMALL"
						>
							Trigger Update
						</Button.Secondary>
					)}
					{marketData &&
						(fundingIsDue ? (
							<span>Due*</span>
						) : (
							<>
								{`${Math.floor(marketData?.estimatedFundingRateCountdownTs / 60)
									.toString()
									.padStart(2, '0')}:${(
									marketData?.estimatedFundingRateCountdownTs % 60
								)
									.toString()
									.padStart(2, '0')}`}
							</>
						))}
				</Text.BODY3>
			</DetailsRow>
			{isMobile && (
				<DetailsRow fullWidth={fullWidth}>
					<Text.BODY3>24h Volume</Text.BODY3>
					<Text.BODY3>
						<Rolling24HrVolumeNumberDisplay
							noLabel
							marketIndex={marketIndex}
							marketType={MarketType.PERP}
						/>
					</Text.BODY3>
				</DetailsRow>
			)}
			<div className="w-full border-b border-container-border">
				<div className="flex flex-row items-center justify-between py-2">
					<Text.BODY3>Open Interest</Text.BODY3>
					<div onClick={toggleOpenInterestExpanded} className="cursor-pointer">
						<Text.BODY3 className="flex flex-row items-center">
							{`${marketData?.openInterest} ${marketInfo.genericInfo.baseAssetSymbol}`}{' '}
							<ChevronDown
								className={twMerge(
									'transition-all ml-1',
									openInterestExpanded ? 'rotate-180' : 'rotate-0'
								)}
							/>
						</Text.BODY3>
					</div>
				</div>
				<div
					className={twMerge(
						'box-border overflow-hidden transition-all duration-300',
						openInterestExpanded ? 'h-[56px]' : 'h-0'
					)}
				>
					<div className="flex flex-row items-center justify-between py-1">
						<Text.BODY3>Short</Text.BODY3>
						<Text.BODY3>{`${marketData?.shortOpenInterest} ${marketInfo.genericInfo.baseAssetSymbol}`}</Text.BODY3>
					</div>
					<div className="flex flex-row items-center justify-between py-1">
						<Text.BODY3>Long</Text.BODY3>
						<Text.BODY3>{`${marketData?.longOpenInterest} ${marketInfo.genericInfo.baseAssetSymbol}`}</Text.BODY3>
					</div>
				</div>
			</div>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Max Open Interest</Text.BODY3>
				<Text.BODY3>{`${marketData?.maxOpenInterest} ${marketInfo.genericInfo.baseAssetSymbol}`}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Taker Fee / Maker Rebate</Text.BODY3>
				<Text.BODY3>{`${parseFloat(
					marketFees.takerFeePct.toFixed(5)
				)}% / ${parseFloat(
					Math.abs(marketFees.makerFeePct).toFixed(5)
				)}%`}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Min. Order Size</Text.BODY3>
				<Text.BODY3>{displayMinOrderSize}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Tooltip content={`Smallest unit of change for the price of an order`}>
					<Text.BODY3>Order Tick Size</Text.BODY3> <Info className="ml-1" />
				</Tooltip>
				<Text.BODY3>{displayOrderTickSize}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Tooltip content={`Smallest unit of change for the size of an order`}>
					<Text.BODY3>Order Step Size</Text.BODY3> <Info className="ml-1" />
				</Tooltip>
				<Text.BODY3>{displayOrderStepSize}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>IMF Factor</Text.BODY3>
				<Text.BODY3>{displayImfFactor}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Margin Maintenance</Text.BODY3>
				<Text.BODY3>{displayMarginMaintenance}%</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Total Liquidation Fee</Text.BODY3>
				<Text.BODY3>{displayLiquidationFee}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3 className="flex flex-row items-center">
					Insurance Fund
					{hasLimitedInsurance && (
						<Tooltip
							allowHover
							className="text-orange-70"
							content={
								<div className="flex flex-col space-y-2">
									<span>
										This market uses a limited insurance fund (fee pool only) to
										protect against levered losses.
									</span>{' '}
									<UIHelpTextLink
										href="https://docs.drift.trade/insurance-fund/insurance-fund-intro"
										text="Learn More"
										className="mt-2"
									/>
								</div>
							}
						>
							<Info className="ml-2" />
						</Tooltip>
					)}
				</Text.BODY3>

				<Text.BODY3>${displayAvailableInsurance}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>P&L Pool</Text.BODY3>
				<Text.BODY3>${displayPnlPoolValue}</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Market Index</Text.BODY3>
				<Text.BODY3>{marketIndex}</Text.BODY3>
			</DetailsRow>
		</div>
	);
};

export const PerpMarketDetailsMemo = memo(PerpMarketDetails);

const _ViewDetailsForMarketButton = ({
	market,
	isLiteMode,
}: {
	market: UIMarket;
	isLiteMode?: boolean;
}) => {
	const driftClientIsReady = useDriftClientIsReady();
	const setDrawerStore = useDrawersStore((s) => s.set);

	const showMarketDetailsPanel = () => {
		if (driftClientIsReady) {
			setDrawerStore((s) => {
				s.marketDetails.uiMarket = market;
				s.marketDetails.goToPnl = false;
				s.marketDetails.show = true;
			});
		}
	};

	return (
		<>
			<button
				className="flex flex-row items-center cursor-pointer"
				onClick={showMarketDetailsPanel}
			>
				<div
					className={`select-none flex flex-col items-start px-2 leading-2 mt-[-3px]`}
				>
					{isLiteMode ? (
						<div className="flex h-full text-interactive-link">
							<Text.BODY2>Market Details</Text.BODY2>{' '}
						</div>
					) : (
						<>
							<div className={`text-text-label`}>
								<Text.MICRO1>About Market</Text.MICRO1>
							</div>

							<div className="flex text-interactive-link">
								<Text.BODY2>View Details</Text.BODY2>{' '}
							</div>
						</>
					)}
				</div>
			</button>
		</>
	);
};

export const ViewDetailsForMarketButton = React.memo(
	_ViewDetailsForMarketButton
);

/**
 * Returns a list of rows with the liquidity pool details for given perp market index
 */
const PoolDetails = ({
	liquidityPool,
	fullWidth,
}: {
	liquidityPool: LiquidityPool;
	fullWidth?: boolean;
}) => {
	const setState = useDriftStore((s) => s.set);

	if (!liquidityPool) return;

	const showPoolPerformance = useCallback(() => {
		setState((s) => {
			s.modals.showLpDetailsModal = false;
			s.modals.showLpPerformanceModal30d = true;
		});
	}, []);

	return (
		<div className="text-text-default">
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>BAL Performance</Text.BODY3>
				<Text.BODY3
					className="hover:cursor-pointer hover:text-text-label"
					onClick={showPoolPerformance}
				>
					View
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Total BAL Shares</Text.BODY3>
				<Text.BODY3>
					{liquidityPool.protocolLpShares
						.add(liquidityPool.totalUserLpShares)
						.prettyPrint(true)}
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Total Liquidity</Text.BODY3>
				<Text.BODY3>
					{liquidityPool.protocolLpQuoteValue
						.add(liquidityPool.totalUserLpQuoteValue)
						.toNotional()}
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>User Provided Liquidity</Text.BODY3>
				<Text.BODY3>
					{liquidityPool.totalUserLpQuoteValue.toNotional()}
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Protocol Provided Liquidity</Text.BODY3>
				<Text.BODY3>
					{liquidityPool.protocolLpQuoteValue.toNotional()}
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>24hr Trading Fees</Text.BODY3>
				{liquidityPool.totalFees24h ? (
					<Text.BODY3>{liquidityPool.totalFees24h.toNotional()}</Text.BODY3>
				) : (
					<SkeletonValuePlaceholder className="w-10 h-2" loading={true} />
				)}
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>30d Est. Fee Only APR</Text.BODY3>
				{liquidityPool.poolFeeApr ? (
					<Text.BODY3 className="text-positive-green">
						{liquidityPool.poolFeeApr.toFixed(2)}%
					</Text.BODY3>
				) : (
					<SkeletonValuePlaceholder className="w-10 h-2" loading={true} />
				)}
			</DetailsRow>
			<DetailsRow fullWidth={fullWidth}>
				<Text.BODY3>Fee Distribution</Text.BODY3>
				<Text.BODY3>80% BAL / 20% Protocol</Text.BODY3>
			</DetailsRow>
		</div>
	);
};

export const LPDetailsMemo = memo(PoolDetails);

/**
 * Returns a list of rows with the user's liquidity pool details for given perp market index
 */
const YourPoolDetails = ({
	liquidityPool,
	fullWidth,
	openOrder,
}: {
	liquidityPool: LiquidityPool;
	fullWidth?: boolean;
	openOrder?: LPOpenOrderData;
}) => {
	if (!liquidityPool) return;

	const actions = useDriftActions();

	const settleLP = useCallback((marketIndex: number) => {
		actions.settlePnl(marketIndex);
	}, []);

	return (
		<div className="text-text-default">
			<div className="w-full pb-2">
				<DetailsRow fullWidth={fullWidth}>
					<Text.BODY3>Liquidity Provided</Text.BODY3>
					<Text.BODY3>{liquidityPool.userQuoteValue.toNotional()}</Text.BODY3>
				</DetailsRow>
				<DetailsRow fullWidth={fullWidth}>
					<Text.BODY3>BAL Shares</Text.BODY3>
					<Text.BODY3>
						{liquidityPool.userLpShares.prettyPrint(true)}
					</Text.BODY3>
				</DetailsRow>
				<DetailsRow fullWidth={fullWidth}>
					<Text.BODY3>BAL Proportion</Text.BODY3>
					<Text.BODY3>{liquidityPool.userLpProportion}</Text.BODY3>
				</DetailsRow>
				<DetailsRow fullWidth={fullWidth}>
					<Text.BODY3>In Open Orders</Text.BODY3>
					<Text.BODY3>
						{openOrder
							? `${openOrder.baseAmountLong
									.abs()
									.printShort(true)} LONG / ${openOrder.baseAmountShort
									.abs()
									.printShort(true)} SHORT`
							: `N/A`}
					</Text.BODY3>
				</DetailsRow>
			</div>
			{liquidityPool.userPerpPosition && (
				<>
					<div className="w-full pt-4 pb-2 border-t text-text-label border-container-border">
						<Text.H5>BAL Position Overview</Text.H5>
					</div>
					<div className="w-full">
						<DetailsRow fullWidth={fullWidth}>
							<Text.BODY3>Position</Text.BODY3>
							<Text.BODY3 className="inline-flex items-center">
								<MarketIcon
									className="mr-0.5"
									marketSymbol={liquidityPool.marketConfig.baseAssetSymbol}
								/>
								{`${BigNum.from(
									liquidityPool.userPerpPosition.baseSize,
									BASE_PRECISION_EXP
								).printShort()} ${liquidityPool.marketConfig.baseAssetSymbol}`}
								<span
									className={`${
										liquidityPool.userPerpPosition.direction === 'short'
											? 'text-positive-red'
											: 'text-positive-green'
									} ml-1`}
								>
									{liquidityPool.userPerpPosition.direction === 'short'
										? 'SHORT'
										: 'LONG'}
								</span>
							</Text.BODY3>
						</DetailsRow>
						<DetailsRow fullWidth={fullWidth}>
							<Text.BODY3>Notional</Text.BODY3>
							<Text.BODY3>
								{BigNum.from(
									liquidityPool.userPerpPosition.notional.abs(),
									QUOTE_PRECISION_EXP
								).toNotional()}
							</Text.BODY3>
						</DetailsRow>

						<DetailsRow fullWidth={fullWidth}>
							<Text.BODY3 className="inline-flex items-center">
								Unsettled P&L
							</Text.BODY3>
							<div className="inline-flex items-center">
								<Text.BODY3
									className={`inline-flex items-center ${
										liquidityPool.userUnsettledPnl.gtZero()
											? 'text-positive-green'
											: 'text-negative-red'
									}`}
								>
									{liquidityPool.userUnsettledPnl.toNotional()}
								</Text.BODY3>
								<Button.Secondary
									id={`settleLpFromModal_${liquidityPool.marketConfig.marketIndex}`}
									size="SMALL"
									className="ml-1"
									//disabled={liquidityPool.userUnsettledPnl.eqZero()}
									onClick={() => {
										settleLP(liquidityPool.marketConfig.marketIndex);
									}}
								>
									<Text.H5>Settle</Text.H5>
								</Button.Secondary>
							</div>
						</DetailsRow>
					</div>
				</>
			)}
		</div>
	);
};

export const YourLPDetailsMemo = memo(YourPoolDetails);
