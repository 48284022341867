import Utility from 'src/components/Inputs/Utility';
import Text from 'src/components/Text/Text';
import React, { PropsWithChildren, ReactNode } from 'react';
import { BigNum } from '@drift-labs/sdk';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Env from '../../environmentVariables/EnvironmentVariables';
import Tooltip from '../Tooltip/Tooltip';
import { Info } from '@drift-labs/icons';
import { twMerge } from 'tailwind-merge';
import { JSX } from 'react';

type StatDisplayProps = PropsWithChildren<{
	leftBorder?: boolean;
	rightBorder?: boolean;
	header: string | ReactNode;
	underline?: boolean;
	headerSize?: 'xs' | 'sm' | 'base' | 'lg';
	className?: string;
	tooltipText?: string;
	onClick?: () => void;
}>;

const NumberDisplay = ({
	value,
	symbolLeft,
	colourSign = false,
	textOverride,
	millify = true,
	loading = false,
	className,
}: {
	value: number;
	symbolLeft?: string;
	colourSign?: boolean;
	textOverride?: string | JSX.Element;
	millify?: boolean;
	loading?: boolean;
	className?: string;
}) => {
	return (
		<Text.BODY2
			className={twMerge(
				`overflow-hidden`,
				colourSign
					? value === 0
						? ``
						: value < 0
						? `text-negative-red-hover`
						: `text-positive-green-hover`
					: 'text-text-default',
				className
			)}
		>
			{loading ? (
				<SkeletonValuePlaceholder
					className="xs:h-[12px] sm:h-[14px] xs:my-[0.5px] sm:my-[2px]"
					loading={true}
				/>
			) : textOverride ? (
				<>{textOverride}</>
			) : (
				<>
					{symbolLeft}
					{millify
						? BigNum.from(value).toMillified()
						: value.toLocaleString(Env.locale)}
				</>
			)}
		</Text.BODY2>
	);
};

const Value = (props: {
	value: string | ReactNode;
	loading?: boolean;
	className?: string;
}) => {
	return (
		<Text.BODY2
			light
			className={`inline-flex overflow-hidden font-numeral text-text-default`}
		>
			{props.loading ? (
				<SkeletonValuePlaceholder
					className="xs:h-[12px] sm:h-[14px] xs:my-[0.5px] sm:my-[2px] w-full"
					loading={true}
				/>
			) : (
				<span className={props.className || ''}>{props.value}</span>
			)}
		</Text.BODY2>
	);
};

const Label = (props: { value: string }) => {
	return (
		<div className={`overflow-hidden font-numeral text-xs text-neutrals-40`}>
			{props.value}
		</div>
	);
};

const StatDisplay = ({ className, ...props }: StatDisplayProps) => {
	return (
		<>
			{props.leftBorder && (
				<div className={`border-r border-container-border`} />
			)}

			<div
				className={twMerge(`flex flex-col`, className)}
				role={props.onClick ? 'button' : undefined}
				onClick={props.onClick}
			>
				<div
					className={`overflow-hidden text-text-label ${
						props.underline ? 'underline' : ''
					}`}
				>
					{props.tooltipText ? (
						<Tooltip
							className="inline-flex items-center"
							content={props.tooltipText}
						>
							{props.header} <Info size={12} className={'inline-flex ml-1'} />
						</Tooltip>
					) : (
						props.header
					)}
				</div>

				<Utility.VERTSPACERXS />

				<div className="flex items-center grow">{props.children}</div>
			</div>

			{props.rightBorder && (
				<div className="border-r h-2/3 border-container-border " />
			)}
		</>
	);
};

StatDisplay.NumberDisplay = React.memo(NumberDisplay);
StatDisplay.Value = React.memo(Value);
StatDisplay.Label = React.memo(Label);
export default StatDisplay;
