import { useCallback, useContext } from 'react';
import { HighLeverageModeConfigSubscriberContext } from 'src/providers/HighLeverageModeConfigSubscriberProvider';

const DEFAULT_DATA = {
	maxUsers: 0,
	currentUsers: 0,
};

const useHighLeverageSpotsOpen = () => {
	const { highLeverageModeConfigSubscriber } = useContext(
		HighLeverageModeConfigSubscriberContext
	);

	const data =
		highLeverageModeConfigSubscriber?.getHighLeverageModeConfigAccountAndSlot()
			?.data || DEFAULT_DATA;

	const updateHighLeverageSlotsOpen = useCallback(() => {
		highLeverageModeConfigSubscriber.fetch();
	}, [highLeverageModeConfigSubscriber]);

	return {
		highLeverageMaxUsers: data.maxUsers,
		highLeverageSpotsOpen: data.maxUsers - data.currentUsers,
		updateHighLeverageSlotsOpen,
	};
};

export default useHighLeverageSpotsOpen;
